import { template as template_0013d34cb1f34c14b91fa9d8e7b701ed } from "@ember/template-compiler";
const FKText = template_0013d34cb1f34c14b91fa9d8e7b701ed(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
